.what-we-do {

}

.what-we-do__item {
  opacity: 0;
}

.card-1:hover .fa-suitcase {
  color: #222222;
}

.fa-suitcase {
  color: #795548;
  cursor: pointer;
}
